export const content = {
    de: `
### Hintergrund
**Der Untergang des Christentums in Europa**

Das Christentum bildet nicht weniger als die Wurzeln der europäischen und westlichen Zivilisation. Heute jedoch sind die Kirchen im deutschsprachigen Raum mehr leer als voll, und der christliche Glaube wird zunehmend als Relikt der Geschichte betrachtet, was auf ein kompliziertes Zusammenspiel historischer, kultureller und philosophischer Veränderungen im westlichen Denken zurückzuführen ist.


**Ein unerwartetes Comeback**

In den letzten Jahrzehnten ist jedoch in der akademischen Welt eine Wiederentdeckung der enormen intellektuellen Plausibilität zentraler christlicher Behauptungen** zu beobachten, die sich langsam ihren Weg in die Populärkultur bahnt. 

Auf der Ebene der kulturellen Bewegungen weltweit ist vielen klar geworden, dass das Christentum bei weitem nicht der Kindermythos ist, als den sie es einst abgetan hatten, sondern im Gegenteil unvergleichliche Schätze an existenzieller Bedeutung und Erdung für die Menschheit im Verständnis der Realität sowie für das menschliche Bewusstsein selbst bietet. 

Ein Großteil dieser Revolution hat im englischsprachigen Raum stattgefunden, und deshalb übersetzen wir viele der Quellen, auf die wir uns stützen, aus dem englischen Original ins Deutsche, um sie einem deutschsprachigen Publikum besser zugänglich zu machen. Andere Quellen, auf die wir zurückgreifen, spiegeln deutsche Denker wider, die auch für die westliche Welt von heute durchdringende Einsichten zu berücksichtigen haben. 

**So ist beispielsweise die Existenz Gottes in akademischen Kreisen aufgrund der Kontingenz und der Feinabstimmung des Universums zu einer weitaus respektableren Hypothese geworden. Weitere wichtige Aspekte des Christentums haben sich ebenfalls als historisch oder philosophisch belastbar erwiesen, z. B. die Auferstehung Jesu, die Immaterialität des Bewusstseins, die Zuverlässigkeit des Neuen Testaments, usw. Der Theismus hat sich als eine völlig vernünftige Alternative zum weit verbreiteten Naturalismus in der westlichen Welt präsentiert.

**Unsere Motivation**

Wir gehören zu der wachsenden Zahl von Menschen, die von den intellektuellen Vorzügen des Christentums überwältigt sind, nachdem sie das Christentum endlich ernst genommen haben. Wir wollen die Erforschung und Diskussion des christlichen Glaubens zugänglicher machen, indem wir philosophische und kulturelle Beiträge aus aller Welt hervorheben.

### Unsere Ziele

1) Gehaltvolle philosophische Argumente für das Christentum auf mittlerer bis hoher Ebene für Christen und Nichtchristen hervorheben.
2) Das historische Christentum auf existenzieller, moralischer und philosophischer Ebene darstellen, und dafür eintreten.
3) Konstruktive Kritik an der modernen Weltanschauung aus christlicher Sicht üben.
4) die philosophische und akademische Bildung unter Christen fördern. 

### Wie funktionieren wir?
1) Mit der Erlaubnis der Autoren übersetzen wir Publikationen aus aller Welt, die unseren Zielen entsprechen, ins Deutsche und veröffentlichen sie auf unserer Website und in unserem Newsletter.
2) Wir knüpfen Kontakte zu deutschen christlichen Denkern und stellen ihre Artikel oder gekürzte Abschnitte ihrer Artikel zur Verfügung und verlinken auf den vollständigen Beitrag auf unserer Website.
3) Wir verfassen unsere eigenen Veröffentlichungen und Zitate (auf Instagram) im Einklang mit den oben genannten Zielen.

Wenn du möchtest, dass deine Arbeit auf Glaubensfutter veröffentlicht wird, kontaktiere uns bitte! 

### Wer sind wir?
Wir sind eine internationale und **ökumenische** Gruppe mit Sitz in Mitteldeutschland. Wir haben unsere eigenen Geschichten der Bekehrung vom Atheismus und Agnostizismus zum Christentum und wollen das, was wir gefunden haben, mit anderen teilen. Unsere Motivation ist einfach: Gott zu ehren, indem wir seine Botschaft für alle zugänglich machen. Wir sind hier, um jedem die Möglichkeit zu geben, das Christentum besser zu verstehen.

**Intellektuelle Ehrlichkeit**

Wir sind zwar davon überzeugt, dass das Christentum aus intellektueller, existenzieller und moralischer Sicht die vernünftigste Sichtweise ist, aber als Studenten der Philosophie legen wir auch großen Wert auf intellektuelle Ehrlichkeit. Aus diesem Grund sind wir auch gerne bereit, über echte intellektuelle Schwachpunkte des christlichen Glaubens zu diskutieren und unsere Sicht der Dinge darzulegen. 

Alle Weltanschauungen haben Schwachstellen, sei es durch die Begrenztheit menschlicher Denkkategorien oder den Stand des akademischen Diskurses, und Aspekte der Realität, die sie nicht so gut zu erklären scheinen. Wir glauben, dass jede Diskussion oder Befürwortung einer Weltanschauung in der Lage sein sollte, solche Punkte zuzulassen, insbesondere angesichts der Tatsache, dass das Vorhandensein von Schwachpunkten leicht durch starke Punkte zu ihren Gunsten überlagert werden kann.

**_Glaubensfutter.de wird leidenschaftlich zur Ehre Gottes in Deutschland aufgebaut und gepflegt ♥_**
`,
    en: `
### Background 

**The downfall of Christianity in Europe**

Christianity constitutes no less than the the roots European and western civilization. Today, however, churches in the German speaking world are more empty than full, and Christian belief is increasingly considered a relic of history, due to a complicated confluence of historical, cultural, and philosophical shifts in western thought.

**An unexpected comeback**

In the last decades, however, a rediscovery of the tremendous intellectual plausibility of key Christian claims** has emerged in academia and is slowly making its way to popular culture. 

On the level of cultural movements worldwide, it has become for many clear that Christianity is far from the child's myth they had once dismissed it as being, but rather in contrast offers incomparable treasures of existential meaning and grounding for humanity in understanding reality as well as human consciousness itself. 

Much of this revolution has occurred in the English speaking sphere, and therefore many of the sources we draw upon we translate from the original English into German in order to make them more accessible to a German speaking audience. Other sources we draw upon reflect German thinkers who also have penetrating insights for the western world today to consider. 

**For example, in academic circles the existence of God has become a far more respectable hypothesis on the basis of the contingency and fine-tuning of the universe. Further important aspects of Christian have also been shown to have considerable amounts of historical or philosophical evidence, such as the resurrection of Jesus, the immateriality of consciousness, the reliability of the New testament, and etc. Theism has presented itself as an entirely reasonable alternative to the widespread naturalism throughout the western world. 

**Our motivation**

We belong to the growing number of people who have been overwhelmed with the intellectual merits of Christianity after finally taking Christianity seriously. We want to make the investigation and discussion of the Christian faith more accessible, through highlighting philosophical and cultural contributions from around the world.

### Our Goals

1. Highlight substantial mid to high-level philosophical arguments for Christianity for Christians and non-Christians.
2. Portray and advocate for historical Christianity on an existential, moral, and philosophical level.
3. Furnish constructive critique of the modern worldview from a Christian perspective.
4. Encourage philosophical and academic literacy among Christians. 

### How do we work?
1) With the permission of the authors, we translate publications aligning with our goals from around the world into German and publish via our website and newsletter.
2) We make connections with German Christian thinkers and either host their articles or shortened sections of their articles, linking to the full post on our website.
3) We author our own publications and quotes (on Instagram) in line with the aforementioned goals.

If you would like your work featured on Glaubensfutter, please contact us! 

### Who are we?
We are an international and **ecumenical** group situated in mid-Germany. We have our own stories of conversion from atheism and agnosticism to Christianity and want to share what we have found. Our motivation is simple: to honor God by making his message available to all. We're here to give everyone an opportunity to better understand Christianity.

**Intellectual Honesty**

While we are convinced that Christianity as the most reasonable view to adopt from a philosophical, existential, and moral perspective, as students of Philosophy we also place a high value on intellectual honesty. For this reason we are also quick to discuss and offer our perspective on any real intellectual weak-points of the Christian faith. 

All worldviews, whether through the limitations of human thought categories or the state of academic discourse, have weak points and aspects of reality that they do not seem to explain as well. We believe any discussion of or advocacy of a worldview should be able to admit such points, especially in light of the fact that the existence of weak-points can easily be overwhelmed by strong points in their favor.

**_Glaubensfutter.de is passionately built and maintained to the Glory of God in Germany ♥_**
  `,
};

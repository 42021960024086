/* eslint-disable multiline-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkDown from 'react-markdown';

import { content } from './AboutUs.markdown';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import SocialMediaSharing from '../../atoms/SocialMediaSharing';
import JoinUsButtons from '../../atoms/JoinUsButtons';
import PageHeader from '../../atoms/PageHeader';
import Head from '../../atoms/Head';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { GFBrand } from '../../models/GFBrand';

class AboutUs extends React.Component
{
    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    render()
    {
        const { locale } = this.props;

        return (
            <section className="about-us-container">
                <Head
                    locale={this.props.locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Über Uns' : 'About Us'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forAboutUsPage(this.props.pageContext.locale).getFullUrl()}
                />
                <PageHeader
                    title="about_us"
                    translateTitle={true}
                    showHr={true}
                />
                <div className="article-reader">
                    <ReactMarkDown>
                        {locale === 'de' ? content.de : content.en}
                    </ReactMarkDown>
                    <hr style={{ marginTop: '3rem' }}/>
                    <SocialMediaSharing
                        title={locale === 'de' ? 'Glaubensfutter: Über Uns' : 'Glaubensfutter: About Us'}
                        style={{ display: 'flex',
                            flexDirection: 'row',
                            margin: '0 auto',
                            justifyContent: 'center',
                            marginBottom: '2rem',
                            marginTop: '1rem' }}
                        childStyle={{
                            padding: '4px',
                            cursor: 'pointer',
                        }}
                        description={
                            locale === 'de' ?
                                'Wir übersetzen englisch-christliche apologetische Werke (Artikel, Podcasts, Videos, Kurzbücher) mit Erlaubnis der Originalautoren mit Hilfe von sprachlich kompetenten Freiwilligen ins Deutsche. Wir haben auch vor, in Zukunft ein Forum zu schaffen, um diese Fragen mit den Interessenten zu diskutieren.'
                                :
                                'We translate English Christian apologetics works (articles, podcasts, videos, short books) into German with the permission of the original authors through the help of linguistically skilled volunteers. We also have future plans to create a forum to discuss these questions with those who are interested.'
                        }
                    />
                </div>
                <JoinUsButtons/>
            </section>
        );
    }
}

AboutUs.propTypes = {
    locale: PropTypes.string,
    pageContext: PropTypes.object,
};

export default AboutUs;

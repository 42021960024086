import AboutUs from '../../modules/ui/pages/AboutUs';
import withGlobalAppLayout from '../../modules/ui/containers/HOC/withGlobalAppLayout';

export default function index(props: any)
{
    return withGlobalAppLayout(AboutUs, {
        locale: props.pageContext.locale,
        hideGfLogo: false,
        ...props,
    });
}
